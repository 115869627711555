import { Role } from "@/constants/Role";
import PermissionDTO from "@/dto/auth/PermissionDTO";

export default class RegisterSublimeEmployeeRequest {
    public firstName: string | null = null;

    public lastName: string | null = null;

    public email: string | null = null;

    public position: string | null = null;

    public officePhoneNumber: string | null = null;

    public officeId: number | null = null;

    public permissions: Array<PermissionDTO> | null = null;
}
