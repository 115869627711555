



















































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import AdminService from "@/services/AdminService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { Role } from "@/constants/Role";
import SublimeOfficeDTO from "@/dto/sublime/office/SublimeOfficeDTO";
import RegisterSublimeEmployeeRequest from "@/dto/sublime/employee/RegisterSublimeEmployeeRequest";
import PermissionDTO from "@/dto/auth/PermissionDTO";


const AppModule = namespace("App");

@Component
export default class SublimeEmployeeCreationModal extends Vue {
    private request = new RegisterSublimeEmployeeRequest();

    private offices: Array<SublimeOfficeDTO> = [];

    private office: SublimeOfficeDTO| null = null;

    private roles: Array<Role> = [];

    private state = null;

    @AppModule.Action
    private startLoading!: () => void

    @AppModule.Action
    private stopLoading!: () => void

    private message = "";

    private successful = false;

    @Prop()
    private onSuccess!: () => void

    created() {
        this.fetchOffices();
    }

    private fetchOffices(): void {
        this.startLoading();
        AdminService.getAllOffices().then(
            res => {
                this.offices = res.data;
                this.stopLoading();
            },
            err => {
                this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                this.successful = false;
                this.stopLoading();
            }
        )
    }

    private save(): void {
        this.$validator.validateAll().then(
            isValid => {
                if (isValid) {
                    if (this.office != null) {
                        this.request.officeId = this.office.id;
                    }
                    if (this.roles.length != 0) {
                        this.request.permissions = this.roles.map(r => new PermissionDTO(r, this.state))
                    }
                    this.startLoading();
                    AdminService.registerSublimeEmployee(this.request).then(
                        () => this.processResp(),
                        err => {
                            this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
                            this.successful = false;
                            this.stopLoading();
                        }
                    )
                }
            }
        )
    }

    processResp() {
        this.successful = true;
        this.stopLoading();
        this.$modal.hideAll();
        this.onSuccess();
    }

};
