import { Role } from "@/constants/Role";

export default class PermissionDTO {
    private role: Role | null = null;

    private state: string | null = null;

    constructor(role: Role | null, state: string | null = null) {
        this.role = role;
        this.state = state;
    }

    public setRole(role: Role | null): void {
        this.role = role;
    }

    public getRole(): Role | null {
        return this.role;
    }

    public setState(state: string | null): void {
        this.state = state;
    }

    public getState(): string | null {
        return this.state;
    }
}
